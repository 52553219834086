<template>
  <v-row class="privacy">
    <v-col cols="12" class="pa-0">
      <div class="title mt-3">
        <v-card
          max-width="1070"
          color="transparent"
          width="100%"
          flat
          tile
          class="mx-auto white--text text-uppercase text-sm-h5 text-h6 font-weight-bold"
        >
          Privacy policy
        </v-card>
      </div>
      <v-card elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-md-15 pa-sm-10 px-4 py-5">
        <v-card tile flat max-width="800" class="mx-auto text-body-2">
          <div class="font-weight-bold text-h6 text-center">Privacy Policy for Coton LLC</div>
          <div class="mt-5 text-body-2">
            <p>
              At CotonSMS, accessible from https://app.coton.amifactory.network, one of our main priorities is the privacy of our visitors.
              This Privacy Policy document contains types of information that is collected and recorded by CotonSMS and how we use it.
            </p>
            <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</p>
            This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the
            information that they shared and/or collect in CotonSMS. This policy is not applicable to any information collected offline or
            via channels other than this website. Our Privacy Policy was created with the help of the
            <a class="input--text" target="_blank" href="https://www.privacypolicygenerator.info">Free Privacy Policy Generator</a>.
          </div>
          <div class="text-body-1 font-weight-bold mt-7">Content</div>
          <ol class="mb-7 text-body-1">
            <li v-for="(item, i) in numberList" :key="item" class="input--text mb-1 link" @click="scrollFix(`item_${i + 1}`)">
              {{ item }}
            </li>
          </ol>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_1">Consent</div>
          <div class="mb-7">By using our website, you hereby consent to our Privacy Policy and agree to its terms.</div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_2">Information we collect</div>
          <div class="mb-5">
            The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to
            you at the point we ask you to provide your personal information.
          </div>
          <div class="mb-5">
            If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the
            contents of the message and/or attachments you may send us, and any other information you may choose to provide.
          </div>
          <div class="mb-7">
            When you register for an Account, we may ask for your contact information, including items such as name, company name, address,
            email address, and telephone number.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_3">How we use your information</div>
          <div class="mb-5">We use the information we collect in various ways, including to:</div>
          <ul class="mb-7">
            <li>Provide, operate, and maintain our website</li>
            <li>Improve, personalize, and expand our website</li>
            <li>Understand and analyze how you use our website</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>
              Communicate with you, either directly or through one of our partners, including for customer service, to provide you with
              updates and other information relating to the website, and for marketing and promotional purposes
            </li>
            <li>Send you emails</li>
            <li>Find and prevent fraud</li>
          </ul>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_4">Log Files</div>
          <div class="mb-7">
            CotonSMS follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting
            companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol
            (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
            number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for
            analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_5">Cookies and Web Beacons</div>
          <div class="mb-5">
            Like any other website, CotonSMS uses 'cookies'. These cookies are used to store information including visitors' preferences,
            and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by
            customizing our web page content based on visitors' browser type and/or other information.
          </div>
          <div class="mb-7">
            For more general information on cookies, please read
            <a class="input--text" href="https://www.generateprivacypolicy.com/#cookies">
              the Cookies article on Generate Privacy Policy website</a
            >.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_6">Advertising Partners Privacy Policies</div>
          <div class="mb-5">You may consult this list to find the Privacy Policy for each of the advertising partners of CotonSMS.</div>
          <div class="mb-5">
            Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that appear on CotonSMS, which are sent directly to users' browser. They automatically
            receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising
            campaigns and/or to personalize the advertising content that you see on websites that you visit.
          </div>
          <div class="mb-7">
            Note that CotonSMS has no access to or control over these cookies that are used by third-party advertisers.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_7">Third Party Privacy Policies</div>
          <div class="mb-5">
            CotonSMS's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions
            about how to opt-out of certain options.
          </div>
          <div class="mb-7">
            You can choose to disable cookies through your individual browser options. To know more detailed information about cookie
            management with specific web browsers, it can be found at the browsers' respective websites.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_8">
            CCPA Privacy Rights (Do Not Sell My Personal Information)
          </div>
          <div class="mb-5">Under the CCPA, among other rights, California consumers have the right to:</div>
          <div class="mb-5">
            Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data
            that a business has collected about consumers.
          </div>
          <div class="mb-5">Request that a business delete any personal data about the consumer that a business has collected.</div>
          <div class="mb-5">Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</div>
          <div class="mb-7">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact
            us.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_9">GDPR Data Protection Rights</div>
          <div class="mb-5">
            We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:
          </div>
          <div class="mb-5">
            The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this
            service.
          </div>
          <div class="mb-5">
            The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also
            have the right to request that we complete the information you believe is incomplete.
          </div>
          <div class="mb-5">
            The right to erasure – You have the right to request that we erase your personal data, under certain conditions.
          </div>
          <div class="mb-5">
            The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under
            certain conditions.
          </div>
          <div class="mb-5">
            The right to object to processing – You have the right to object to our processing of your personal data, under certain
            conditions.
          </div>
          <div class="mb-5">
            The right to data portability – You have the right to request that we transfer the data that we have collected to another
            organization, or directly to you, under certain conditions.
          </div>
          <div class="mb-7">
            If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact
            us.
          </div>
          <div class="font-weight-bold mb-2 text-center text-body-1" ref="item_10">Children's Information</div>
          <div class="mb-5">
            Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to
            observe, participate in, and/or monitor and guide their online activity.
          </div>
          <div>
            CotonSMS does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do
            our best efforts to promptly remove such information from our records.
          </div>
        </v-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      numberList: [
        'Consent',
        'Information we collect',
        'How we use your information',
        'Log Files',
        'Cookies and Web Beacons',
        'Advertising Partners Privacy Policies',
        'Third Party Privacy Policies',
        'CCPA Privacy Rights (Do Not Sell My Personal Information)',
        'GDPR Data Protection Rights',
        'Children\'s Information',
      ],
    };
  },
  methods: {
    scrollFix(refName) {
      this.$refs[refName].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 10px !important;
}
</style>
